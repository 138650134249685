import { GridColumn as Column } from '@progress/kendo-react-grid'
import { DateTimeCell} from 'components/content-pool/kendocells'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { AppleIcon } from 'components/icons/apple'
import { FacebookIcon } from 'components/icons/facebook'
import { GoogleIcon } from 'components/icons/google'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import * as GA from 'google-analytics'
import get from 'lodash/get'
import React, {Component} from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getRecaptchaToken, loadRecaptcha } from 'recaptcha'
import { Roles } from 'shared/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import * as Errors from 'shared_server_client/types/error'
import { AjaxWrapper, HttpMethod } from '../../../generic/ajaxWrapper'
import * as GenericRedux from '../../../generic/genericRedux'
import { PagingSource, RasaDataGrid } from '../../../generic/rasaDataGrid'
import * as Utils from '../../../generic/utility'
import * as LoginConstants from '../../../pages/login/constants'
import {UsageMeterComponent} from '../../../shared/usage-meter-component'
import * as Constants from '../constants'
import '../styles.css'
import { InviteUserModal } from './modals'
import { FORGOT_PASSWORD_SUCCESS_CONTENT } from '../../../pages/forgot-password/constants'
import { isTruthy, tryParseJSON } from '../../../generic/utility'
import * as TeamCosntants from '../constants'
import { DUMMY_DATE } from '../../../shared_server_client/constants'
import { TransferUserModal } from './transferUserModal'
import { Community } from '../types'

type TeamMember = any
type SettingsTeamProps = GenericRedux.DatasetComponentProps<TeamMember> & GenericRedux.AllComponentPropsWithModal<any>

interface SettingsTeamState {
  accountId: string,
  accountOwnerUserId: string,
  canAddUser: boolean,
  communities: any[],
  communityId: string,
  communityAccountId: string,
  currentUserCount?: number,
  forceReload: boolean,
  inviteCount: number,
  invitedEmail: string,
  invitedEmailValid: boolean,
  inviteInProgress: boolean,
  isAccountOwner: boolean,
  isAdminUser: boolean,
  isSuperUser: boolean,
  isNoMoreUpgradeAvailable: boolean,
  loggedInUserId: number,
  maxUserCount?: number
  selectedRole: string,
  showInvite: boolean,
  showResetPasswordEmailModal: boolean,
  showTransferOwnershipModal: boolean,
  selectedOwner?: any,
  selectedRecord?: any,
  users: any[],
}

class SettingsTeamComponent extends Component<SettingsTeamProps, SettingsTeamState> {
  public static contextType = RasaContext
  constructor(props: SettingsTeamProps) {
    super(props)

    this.state = {
      accountId: '',
      accountOwnerUserId: '',
      canAddUser: false,
      communities: [],
      communityId: '',
      communityAccountId: '',
      forceReload: false,
      inviteCount: 0,
      invitedEmail: '',
      invitedEmailValid: false,
      inviteInProgress: false,
      isAccountOwner: false,
      isAdminUser: false,
      isSuperUser: false,
      isNoMoreUpgradeAvailable: false,
      loggedInUserId: 0,
      showInvite: false,
      selectedRole: 'community_admin',
      showResetPasswordEmailModal: false,
      showTransferOwnershipModal: false,
      users: [],
    }
    this.inviteUser = this.inviteUser.bind(this)
  }

  public componentDidMount = () => {
    loadRecaptcha()
    this.context.user.init().then(({person, activeCommunity}) => {
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.usageStats) {
        this.setState({
          canAddUser: activeCommunity.billingInfo.usageStats.canAddUser,
          maxUserCount: activeCommunity.billingInfo.usageStats.currentPlanMaxUsers,
        })
      }
      this.setState({
        accountId: activeCommunity.role === Roles.super_admin ?
          activeCommunity.billingInfo.productSubscription.account_id : person.accountId,
        accountOwnerUserId: (activeCommunity.data && activeCommunity.data.account_owner_user_id) ?
         activeCommunity.data.account_owner_user_id : '',
        communityId: activeCommunity.communityId,
        communityAccountId:  (activeCommunity.data && activeCommunity.data.community_account_id) ?
        activeCommunity.data.community_account_id : '',
        isSuperUser: activeCommunity.role === Roles.super_admin,
        isAdminUser: (activeCommunity.data.user_role === Roles.community_admin && activeCommunity.role === Roles.community_admin),
        isAccountOwner: Utils.isAccountOwner(person, activeCommunity),
        isNoMoreUpgradeAvailable: Utils.noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
        loggedInUserId: person.userId,
      })
    })
  }

  public render() {
    return (
      <div className="settings-team-wrapper">
        <InviteUserModal
          data={this.props.modals}
          closeModal={this.props.closeModal}
          saveModal={this.props.saveModal}
          onSave={this.inviteUser}
          onUpdate={this.updateUser}/>
        <TransferUserModal
          data={this.props.modals}
          closeModal={this.props.closeModal}
          saveModal={this.props.saveModal}
          onSave={this.transferUser}/>
        <div>
        <div className="header-container">
        <HeaderComponent
          title={'Settings'}
          subTitle={'Team'}
          description={['Invite a team member to access your newsletter.']}
        />
        {this.renderUserLimitContainer()}
      </div>
        </div>
        <div className="clearfix">
        <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="user"
            datasetName="accountUsers"
            editDefaultState={false}
            onDataReceived={this.dataReceived}
            forceReload={this.state.forceReload}
            sortable={true}
            pageable={false}
            pagingSource={PagingSource.local}
            data={this.props.data}>
          <Column
            cell={this.getAccountType} width={60} sortable={false}/>
          <Column field="first_name" title="First Name" />
          <Column field="last_name"title="Last Name"/>
          <Column field="email" title="Email"/>
          <Column cell={this.RoleCell} title="Role"/>
          <Column cell={this.getStatus} title="Status" />
          {
            this.state.isSuperUser &&
            <Column field="last_login" cell={this.LastLoginCell} title="Last Login" editable={false} width={150}/>
          }
          <Column field="created" title="Date Added" editable={false} cell={DateTimeCell} width={150}/>
          {(this.state.isSuperUser || this.state.isAdminUser) &&
          <Column cell={this.EditCell} width={50}/>}
        </RasaDataGrid>
        </div>
        {this.getOwnershipModalJSX()}
        {this.getResetPasswordEmailModal()}
      </div>
    )
  }

  protected onArchive = (props: EditCellProps, event: any) => {
    if (props.dataItem.account_type === Constants.MEMBER_STATUS_INVITATION) {
      this.context.entityMetadata.getEntityObject('invitation')
        .then((entity) => {
          entity.setRecordId(this.state.communityId, props.dataItem.id)
          entity.data.is_active = false
          return entity.save().then(() =>
          this.setState({
            forceReload: true,
            inviteCount: this.state.inviteCount - 1,
          }, () => this.setState({forceReload: false})),
          )
        })
    } else {
      props.onChange({
        dataItem: props.dataItem,
        syntheticEvent: event,
        field: 'is_archived',
        value: true,
      })
    }
  }

  protected renderUserLimitContainer = () => {
    const isUnlimited: boolean = this.state.maxUserCount === BillingPlan.UNLIMITED_VALUE
      || this.state.maxUserCount === -1
    const currentUsersCount: number = this.state.users.length
    const maxUsersCount: number = this.state.maxUserCount
    const isLimitReached: boolean = currentUsersCount >= maxUsersCount
    const disableActionButton: boolean = !this.state.canAddUser
    const description: string = 'You\'ve reached your team member limit, please upgrade your plan to invite more team member.'
    const handleClickEvent = () => {
      this.props.openModal(InviteUserModal.key, null)
    }

    return <UsageMeterComponent
      actionButton={{
        disabled: disableActionButton,
        callback: handleClickEvent,
        label: 'Invite User'
      }}
      entity='team member'
      isSuperUser={this.state.isSuperUser}
      isUnlimited={isUnlimited}
      isLimitReached={isLimitReached}
      currentCount={currentUsersCount}
      maxLimit={maxUsersCount}
      source={GA.UpgradeSource.AddUser}
      progressText='Users Used'
      progressDescription={description}
      showContactToRasa={this.state.isNoMoreUpgradeAvailable}
    />
  }

  private dataReceived = (data) => {
    this.setState({
      users: data.data,
    })
  }

  private RoleCell = (props: EditCellProps) => {
    return <td>{Constants.getRoleDisplayName(props.dataItem.user_role)}</td>
  }

  private LastLoginCell = (props: EditCellProps) => {
    if(props.dataItem.last_login === DUMMY_DATE) {
      return <td></td>
    }
    return <DateTimeCell {...props}></DateTimeCell>
  }
  private EditCell = (props: EditCellProps) => {
    return props.dataItem.owner_account_id === props.dataItem.account_id
      || Utils.isTruthy(props.dataItem.is_archived) ?
        <td></td> :
        <EditableDropdownCell {...props}
          canInactive={this.canInactive(props)}
          canArchive={this.canArchive(props)}
          resendInviteLabel={this.resendInviteLabel(props)}
          canEdit={props.dataItem.user_id && Utils.isTruthy(props.dataItem.is_active) && this.state.accountOwnerUserId !== props.dataItem.user_id}
          canSendResetPasswordEmail={this.state.isSuperUser || this.state.isAdminUser || this.state.loggedInUserId === props.dataItem.account_owner_user_id}
          onEdit={props.dataItem.user_id && Utils.isTruthy(props.dataItem.is_active) ? this.editUserClick : null}
          showCustomLink={this.showTransferOwnershipLink(props)}
          customLabel="Transfer Ownership"
          onCustomClick={this.showTransferConfirmationModal}
          canResendInvite={this.canResendInvite(props)}
          onSendResetPasswordEmail={this.showResetPasswordConfirmationModal}
          onArchive={this.onArchive}
          onInactive={this.onInactive}
          onResendInvite={this.onResendInvite}
          showTransferUserLink={this.showTransferUserLink(props)}
          onTransferUser={this.onTransferUser}
          inactiveLabel="Disable"/>
  }

  private resendInviteLabel = (props: EditCellProps): string => {
    return props.dataItem.account_type === Constants.MEMBER_STATUS_INVITATION
    || (
      this.state.isSuperUser
      && props.dataItem.user_id === props.dataItem.account_owner_user_id
      && props.dataItem.invitation_status !== Constants.MEMBER_STATUS_ACCEPTED
      && Utils.isTruthy(props.dataItem.is_active)
    ) ? 'Resend Invite' : 'Enable'
  }

  private canResendInvite = (props: EditCellProps): boolean => {
    return (!isTruthy(props.dataItem.is_active)
      || !isTruthy(props.dataItem.user_community_is_active)
      || props.dataItem.account_type === TeamCosntants.MEMBER_STATUS_INVITATION)
      || (
        this.state.isSuperUser
        && props.dataItem.user_id === props.dataItem.account_owner_user_id
        && props.dataItem.invitation_status !== Constants.MEMBER_STATUS_ACCEPTED
      )
  }
  private onResendInvite = (event: any, props: EditCellProps) => {
    if (props.dataItem.account_type === Constants.MEMBER_STATUS_INVITATION
      || (
        this.state.isSuperUser
        && Utils.isTruthy(props.dataItem.is_active)
        && props.dataItem.invitation_status !== Constants.MEMBER_STATUS_ACCEPTED
      )) {
      const communityIds = props.dataItem.identity_community_ids ? props.dataItem.identity_community_ids.split(',') : []
      const communityNames = props.dataItem.community_names ? props.dataItem.community_names.split(',') : []
      const communityIdentifiers = props.dataItem.community_identifiers ? props.dataItem.community_identifiers.split(',') : []
      const selectedCommunities = communityIds.map((id, index) => ({ id, name: communityNames[index], identifier: communityIdentifiers[index] }));
      this.inviteUser(
        props.dataItem.first_name,
        props.dataItem.last_name,
        props.dataItem.email,
        selectedCommunities,
        props.dataItem.user_role,
        true,
      )
    } else if (!Utils.isTruthy(props.dataItem.user_community_is_active)) {
      this.loadUserCommunities().then((communities) => {
        if (communities[0].length === 1) {
          const addedCommunityIds = communities[0].map((c) => c.identity_community_id)
          this.updateUser(props.dataItem.user_id, addedCommunityIds, [], props.dataItem.user_role)
        } else {
          this.props.openModal(InviteUserModal.key, {
            isForEnablingUser: true,
            userId: props.dataItem.user_id,
          })
        }
      })
    } else {
      props.onChange({
        dataItem: props.dataItem,
        syntheticEvent: event,
        field: 'is_active',
        value: true,
      })
    }
  }

  private canInactive = (props: EditCellProps): boolean => {
    return this.state.accountOwnerUserId !== props.dataItem.user_id
      && Utils.isTruthy(props.dataItem.is_active)
      && Utils.isTruthy(props.dataItem.user_community_is_active)
      && this.state.isSuperUser
      && this.state.isAccountOwner
  }

  private canArchive = (props: EditCellProps): boolean => {
    return !Utils.isTruthy(props.dataItem.is_archived)
      && this.state.accountOwnerUserId !== props.dataItem.user_id
      && this.state.isSuperUser
      && this.state.isAccountOwner
  }
  private showTransferOwnershipLink = (props: EditCellProps) => {
    return this.state.accountOwnerUserId !== props.dataItem.user_id
      && Utils.isTruthy(props.dataItem.is_active)
      && (this.state.isSuperUser || this.state.isAccountOwner)
  }

  private showTransferUserLink = (props: EditCellProps): boolean => {
    return this.state.accountOwnerUserId !== props.dataItem.user_id
    && this.state.isSuperUser
    && Utils.isTruthy(props.dataItem.is_active)
  }

  private onTransferUser = (event: React.SyntheticEvent, props: EditCellProps) => {
    if(!this.showTransferUserLink(props)){
      return
    }
    this.props.openModal(TransferUserModal.key, {
      removeCommunityIds: props.dataItem.identity_community_ids.split(','),
      role: props.dataItem.user_role,
      userId: props.dataItem.user_id,
    })
  }

  private onInactive = (event: any, props: EditCellProps) => {
    if (props.dataItem.account_type === Constants.MEMBER_STATUS_INVITATION) {
      this.context.entityMetadata.getEntityObject('invitation')
              .then((entity) => {
                entity.setRecordId(this.state.communityId, props.dataItem.id)
                entity.data.is_active = false
                return entity.save().then(() =>
                this.setState({
                  inviteCount: this.state.inviteCount - 1,
                }))
              })
    } else {
      props.onChange({
        dataItem: props.dataItem,
        syntheticEvent: event,
        field: 'is_active',
        value: false,
      })
    }
  }
  private showTransferConfirmationModal = (dataItem: any) => {
    this.setState({
      selectedOwner: dataItem,
      showTransferOwnershipModal: true,
    })
  }

  private showResetPasswordConfirmationModal = (dataItem: any) => {
    this.setState({
      selectedRecord: dataItem,
      showResetPasswordEmailModal: true,
    })
  }

  private onTransferOwnership = () => {
    this.context.entityMetadata.getEntityObject('account')
      .then((entity) => {
        entity.setRecordId(this.state.communityId, this.state.selectedOwner.account_id)
        entity.data.owner_user_id = this.state.selectedOwner.user_id
        return entity.save().then(() => {
          Utils.logout()
        })
      });
  }
  private editUserClick = (dataItem) => {
    this.props.openModal(InviteUserModal.key, {
      userId: dataItem.user_id,
      selectedCommunities: Utils.isTruthy(dataItem.user_community_is_active) ? dataItem.identity_community_ids : null,
      userRole: Constants.getRoleDisplayName(dataItem.user_role),
    })
  }
  private onModalClose = () => {
    this.setState({
      showTransferOwnershipModal: false,
      selectedOwner: null,
    })
  }

  private resetPasswordResetStates = () => {
    this.setState({
      selectedRecord: null,
      showResetPasswordEmailModal: false
    })
  }

  private handlePasswordResetRequest = () => {
    const forgetPasswordEndpoint: string = AjaxWrapper.getServerUrl() + '/auth/forgot-password'

    AjaxWrapper.ajax(
      forgetPasswordEndpoint,
      HttpMethod.POST,
      {
        email: this.state.selectedRecord.email
      },
    ).then(() => {
      this.context.store.dispatch(Flash.showFlashMessage(FORGOT_PASSWORD_SUCCESS_CONTENT))
    }).catch((err) => {
      this.context.store.dispatch(Flash.showFlashError())
    })
    this.resetPasswordResetStates()
  }

  private getAccountType(props: EditCellProps) {
    if (props.dataItem.identity_provider === LoginConstants.GOOGLE_IDENTITY_PROVIDER) {
      return <td>
        <div className="account-type">
          {(props.dataItem.account_owner_user_id === props.dataItem.user_id)
            && <div className="owner-icon"><i className="fa fa-star" aria-hidden="true"></i></div>}
          <GoogleIcon svgwidth={16} svgheight={16} />
        </div>
      </td>
    } else if (props.dataItem.identity_provider === LoginConstants.FACEBOOK_IDENTITY_PROVIDER) {
      return <td>
        <div className="account-type">
          {(props.dataItem.account_owner_user_id === props.dataItem.user_id)
            && <div className="owner-icon"><i className="fa fa-star" aria-hidden="true"></i></div>}
          <FacebookIcon svgwidth={16} svgheight={16}/>
        </div>
      </td>
    } else if (props.dataItem.identity_provider === LoginConstants.APPLE_IDENTITY_PROVIDER) {
      return <td>
        <div className="account-type">
          {(props.dataItem.account_owner_user_id === props.dataItem.user_id)
            && <div className="owner-icon"><i className="fa fa-star" aria-hidden="true"></i></div>}
          <AppleIcon svgwidth={16} svgheight={16}/>
        </div>
      </td>
    } else {
      return <td>
        <div className="account-type">
          {(props.dataItem.account_owner_user_id === props.dataItem.user_id)
            && <div className="owner-icon"><i className="fa fa-star" aria-hidden="true"></i></div>}
          <div>
            <img src="https://rasa.io/wp-content/uploads/2020/06/cropped-r-mark-cyan-transparent-large-03-32x32.png" />
          </div>
        </div>
      </td>
    }
  }

  private getStatus(props: EditCellProps) {
    if (props.dataItem.account_type === Constants.MEMBER_STATUS_ACCOUNT) {
      if (Utils.isTruthy(props.dataItem.is_archived)) {
        return <td>{Constants.ACCOUNT_STATUS_ARCHIVED}</td>
      } else if (Utils.isTruthy(props.dataItem.is_active) && Utils.isTruthy(props.dataItem.user_community_is_active)) {
        return <td>{Constants.ACCOUNT_STATUS_ACTIVE}</td>
      } else {
        return <td>{Constants.ACCOUNT_STATUS_INACTIVE}</td>
      }
    } else if (new Date(props.dataItem.expiration_date) < new Date()) {
      return <td>{Constants.ACCOUNT_STATUS_EXPIRED}</td>
    } else {
      return <td>{Constants.ACCOUNT_STATUS_PENDING}</td>
    }
  }

  private inviteUser = (firstName, lastName, inviteEmail?, selectedCommunities?, userRole?, isResend = false) => {
    return getRecaptchaToken('inviteUser').then((recaptcha) => {
      this.setState({inviteInProgress: true})
      const url: string = AjaxWrapper.getServerUrl() + `/${this.state.communityId}/invite/user`
      return AjaxWrapper.ajax(url, HttpMethod.POST, {
        email: inviteEmail || this.state.invitedEmail,
        first_name: firstName,
        last_name: lastName,
        communities: selectedCommunities,
        accountId: this.state.accountId,
        role: userRole,
        isResend,
        recaptcha})
      .then((res) => {
        this.setState({
          inviteInProgress: false,
          inviteCount: this.state.inviteCount + 1,
          forceReload: true,
        }, () => this.setState({forceReload: false}))
        this.context.store.dispatch(Flash.showFlashMessage(Constants.INVITATION_SENT))
      }).catch((response) => {
        const rawMessage = get(response, 'response.error.message')
        const jsonObject = tryParseJSON(rawMessage)
        let formattedMessage = Errors.getErrorMessage(jsonObject.code || rawMessage)
        if (jsonObject.field) {
          formattedMessage = formattedMessage.replace(/__FIELD__/g, jsonObject.field)
        }
        this.setState({inviteInProgress: false})
        this.context.store.dispatch(Flash.showFlashError(formattedMessage || Constants.INVITATION_FAILED))
        // pass exception to component where that method is called to prevent the close of modal
        throw response
      })
    })
  }

  private transferUser = (removedCommunities: string[], userId: string, role: string, accountId: number, addedCommunities: Community[]) => {
    const url: string = AjaxWrapper.getServerUrl() + `/${this.state.communityId}/account/transfer`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {
      accountId,
      addedCommunities: addedCommunities.map((community) => community.id),
      removedCommunities,
      role,
      userId
    })
      .then((res) => {
        this.setState({
          inviteInProgress: false,
          inviteCount: this.state.inviteCount - 1,
          forceReload: true,
        }, () => this.setState({forceReload: false}))
        this.context.store.dispatch(Flash.showFlashMessage(Constants.TRANSFER_SUCCESS))
      }).catch((response) => {
        this.setState({inviteInProgress: false})
        this.context.store.dispatch(Flash.showFlashError(
          Errors.getErrorMessage(get(response, 'response.message')) || Constants.TRANSFER_FAILED))
      })
  }

  private updateUser = (userId, addedCommunities, removedCommunities, userRole) => {
    this.setState({inviteInProgress: true})
    const url: string = `${AjaxWrapper.getServerUrl()}/user/${this.state.communityId}/update/${userId}`
    return AjaxWrapper.ajax(url, HttpMethod.POST, {addedCommunities, removedCommunities,
      role: userRole})
    .then((res) => {
      this.setState({
        inviteInProgress: false,
        forceReload: true,
      }, () => this.setState({forceReload: false}))
      this.context.store.dispatch(Flash.showFlashMessage(Constants.USER_UPDATED))
    }).catch((response) => {
      this.setState({inviteInProgress: false})
      this.context.store.dispatch(Flash.showFlashError(
        Errors.getErrorMessage(get(response, 'response.message')) || Constants.INVITATION_FAILED))
    })
  }
  private getOwnershipModalJSX(): JSX.Element {
    return (
      <div>
        <Modal isOpen={this.state.showTransferOwnershipModal}
               className="settings-account-ownership-modal"
               size="lg"
               fade={false}
               centered={true} >
          <ModalHeader>Transfer Ownership Confirmation</ModalHeader>
          <ModalBody className="settings-account-ownership-modal-body">
            <div>
              <h4>Are You Sure?</h4>
              <p>You are about to change the primary owner to {
                this.state.selectedOwner ? this.state.selectedOwner.first_name : ''
                }.
                You will immediately be logged out of the dashboard. Would you like to proceed?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onTransferOwnership}>
              Yes
            </Button>
            <Button onClick={this.onModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private getResetPasswordEmailModal = (): JSX.Element  => {
    return (
      <div>
        <Modal isOpen={this.state.showResetPasswordEmailModal}>
          <ModalHeader>Password Reset Email Confirmation</ModalHeader>
          <ModalBody>
            <div>
              <h4>Are You Sure?</h4>
              <p>
                You are about to send password reset email to
                {
                  this.state.selectedRecord ?
                    <a href={`mailto:${this.state.selectedRecord.email}`}> {this.state.selectedRecord.email}</a>  : ''
                }.
              </p>
              <p>Would you like to proceed?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.handlePasswordResetRequest()}>
              Yes
            </Button>
            <Button onClick={this.resetPasswordResetStates}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private loadUserCommunities = () => {
    if (this.state.communities.length === 0) {
      const params = [
        { param: 'accountId', value: this.state.communityAccountId },
      ]
      return new Dataset().loadCommunityDataset('userNewsletters', this.state.communityId, params)
    }
    return Promise.resolve(this.state.communities)
  }
}

export const SettingsTeam = GenericRedux.registerNewComponentWithModals<any>(
  SettingsTeamComponent, 'settings_team',
  [InviteUserModal.key, TransferUserModal.key])
